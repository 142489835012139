import { GetServerSideProps, NextPage } from "next";
import { getProviders, useSession } from "next-auth/react";
import Head from "next/head";
import Router from "next/router";
import { useEffect } from "react";
import CustomLink from "../components/common/CustomLink";
import LoginForm from "../components/profile/LoginForm";

interface Props {
  providers?: any;
  //session?: any;
}

const Login: NextPage<Props> = ({providers}) => {
  const { status } = useSession()
  console.log({providers,status})

  /*
  let errors = []
  const req = useRouter();
  console.log(req)
  if(req.query?.error){
    console.log(req.query.error)
    errors = ['Invalid credentials']
    //setErrors(['Invalid credentials']);
  }
  */
  
  useEffect(() => {
    if(status === "authenticated"){
      Router.push('/');
    }
  },[status])


  if(status === "authenticated") return null;

  return (
    <>
      <Head>
        <title>Iniciar sesión - Geninquieta (GI) </title>
        <meta name="description" content="Iniciar sesión en Geninquieta (GI) para acceder a contenido de usuarios. (Crear ofertas, aplicar, comentar...)" />
      </Head>
      <div className="auth-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">Iniciar Sesión</h1>
              <p className="text-xs-center">
                <CustomLink href="/register" as="/register">
                  Nuevo en GI?
                </CustomLink>
              </p>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      providers: await getProviders(),
      //session: await getSession(context),
    },
  };
}

export default Login;
