import PropTypes from 'prop-types';
import React from 'react';

interface CustomErrorProps {
  msg: string;
  onDismiss: () => void;
}


const CustomError = ({ msg, onDismiss }: CustomErrorProps) => {
  if(!msg) return(<span></span>)
  return (
    <div className='alert alert-info alert-dismissible show' role='alert'>
      {msg}
      <button type='button' className='close' data-dismiss="alert" aria-label='Close' onClick={onDismiss}>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
  );
};

CustomError.propTypes = {
  msg: PropTypes.string.isRequired
};

export default CustomError;