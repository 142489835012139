import { signIn } from "next-auth/react";
import React from "react";
import CustomError from "../common/CustomError";


const LoginForm = () => {
  
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const dismissError = React.useCallback(
    () => setError(''),
    []
  );

  const handleEmailChange = React.useCallback(
    (e) => setEmail(e.target.value),
    []
  );
  const handlePasswordChange = React.useCallback(
    (e) => setPassword(e.target.value),
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      //const { data, status } = await UserAPI.login(username, password);
      let res = await signIn('credentials', { redirect: false, email, password });
      
      if (!res?.ok) {
        setError('Invalid credentials');
        setLoading(false);
      }
      else{
        console.log("RES",res)
        //window.localStorage.setItem("user", JSON.stringify(res.data));
        //mutate("user", res?.data);
        //Router.push('/');
      }
      /*
      if (data?.data) {
        window.localStorage.setItem("user", JSON.stringify(data.data));
        mutate("user", data?.data);
        Router.push("/");
      }*/
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      {error && <CustomError msg={error} onDismiss={dismissError} />}
      
      <form onSubmit={handleSubmit}>
        <fieldset>
          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
          </fieldset>

          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </fieldset>

          <button
            className="btn btn-lg btn-primary pull-xs-right"
            type="submit"
            disabled={isLoading}
          >
            Iniciar Sesión
          </button>
        </fieldset>
      </form>
    </>
  );
};

export default LoginForm;
